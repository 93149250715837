import { SagaIterator } from '@redux-saga/core';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getInstallCmdApi, getInstanceListApi, setupAgentApi } from '../api';
import * as onboard from '.';

export function* onGetInstallCmd({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => getInstallCmdApi(payload));
    yield put(onboard.getInstallCmdFulfilled(data));
  } catch (e: any) {
    yield put(onboard.getInstallCmdRejected(e?.message));
  }
}

export function* onGetInstanceList({ payload }: any): SagaIterator {
  try {
    const data = yield call(() => getInstanceListApi(payload));
    yield put(onboard.getInstanceListFulfilled(data));
  } catch (e: any) {
    const err =  e?.message ? e.message :  e
    yield put(onboard.getInstanceListRejected(err));
  }
}

export function* onSetupInstallAgent({ payload }: any): SagaIterator {
  try {
    const {data} = yield call(() => setupAgentApi(payload));
    yield put(onboard.setupAgentInstallFulfilled(data));
  } catch (e: any) {
    const err =  e?.message ? e.message :  e
    yield put(onboard.setupAgentInstallRejected(err));
  }
}

// Watcher Saga
export function* onboardWatcherSaga(): SagaIterator {
  yield takeEvery('onboard/getInstallCmd', onGetInstallCmd);
  yield takeLatest('onboard/getInstanceList', onGetInstanceList);
  yield takeEvery('onboard/setupAgentInstall', onSetupInstallAgent);
}

export default onboardWatcherSaga;
